import React, { useEffect, useState, useRef, useCallback, createContext, useContext } from 'react';
import { parse } from 'node-html-parser';
//import ReactHtmlParser from 'react-html-parser';

//import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import mapStyles from './mapStyles';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import {
	Combobox,
	ComboboxInput,
	ComboboxPopover,
	ComboboxList,
	ComboboxOption,
	ComboboxOptionText
} from '@reach/combobox';
//import '@reach/combobox/styles.css';
import axios from 'axios';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { Loader } from '@googlemaps/js-api-loader';
import haversine from 'haversine-distance';
import iconLocation from './img/location-marker.png'
import iconSearch from './img/icon-search.png'

let gkey = 'AIzaSyDEO81MsfJ8j6eatcujDO8TzIQBmprqEX0';

const loader = new Loader({
	apiKey: gkey,
	version: 'weekly'
});

const libraries = ['places'];
const mapContainerStyle = {
	width: '100%',
	height: '100%',
	border: '2px solid #ccc'
};

const options = {
	styles: mapStyles,
	disableDefaultUI: true,
	zoomControl: true
};

const ValueContext = createContext({});

function App(apiKey) {

	useEffect(async () => {
		let res;

		res = await axios.get('https://elementatmanheim.com/wp-json/atd/v1/location');

		let brandLocations;

		setStaticLocations(res.data)
		setManheimLocations(res.data);

		navigator.geolocation.getCurrentPosition(
			(position) => {
				setUserLocation({
					lat: position.coords.latitude,
					lng: position.coords.longitude
				});

				panTo({
					lat: position.coords.latitude,
					lng: position.coords.longitude
				});

				if (brandLocations) {
					const newLocations = brandLocations.map((mLocation) => ({
						...mLocation,
						distance: Math.round(
							(haversine(
								{ lat: parseFloat(mLocation.map.lat), lng: parseFloat(mLocation.map.lng) },
								{ lng: position.coords.longitude, lat: position.coords.latitude }) * 0.000621) * 10 / 10
						)
					}))
						.sort(({ distance: a }, { distance: b }) => b - a)
						.reverse();

					setLocations(newLocations);
				} else {
					const newLocations = res.data
						.map((mLocation) => ({
							...mLocation,
							distance: Math.round(
								(haversine(
									{ lat: parseFloat(mLocation.map.lat), lng: parseFloat(mLocation.map.lng) },
									{ lng: position.coords.longitude, lat: position.coords.latitude }) * 0.000621) * 10 / 10
							)
						}))
						.sort(({ distance: a }, { distance: b }) => b - a)
						.reverse();

					setLocations(newLocations);
				}


			},
			() => null,
			options
		);
	}, [markers]);

	useEffect(() => {

		if (!markers) {
			const createMarkers = async () => {
				let res;

				res = await axios.get('https://elementatmanheim.com/wp-json/atd/v1/location');

				setMarkers(res.data);
			}
			createMarkers()
		}
	}, [markers])

	apiKey = gkey;
	const [manheimLocations, setManheimLocations] = useState([]);
	const [staticLocations, setStaticLocations] = useState([])
	const [markers, setMarkers] = useState(null);
	const [selected, setSelected] = useState(null);
	const [userLocation, setUserLocation] = useState(center);
	const [searchLat, setSearchLat] = useState(0);
	const [searchLong, setSearchLong] = useState(0);
	const [distance, setDistance] = useState(0);
	const [locationMessage, setLocationMessage] = useState('loaded')
	//const [ term, setTerm ] = useState('');
	const [isInfoWindowShowing, setIsInfoWindowShowing] = useState(false)
	const [center, setCenter] = useState({
		lng: -84.484319,
		lat: 34.0581564
	});
	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: gkey,
		libraries
	});

	const mapRef = useRef();
	const onMapLoad = useCallback((map) => {
		mapRef.current = map;
	}, []);

	const setLocations = (newLocations) => {
		setManheimLocations(newLocations);
	};

	const orderByDistance = (newCoordinates) => {
		setLocationMessage(null)
		newCoordinates = staticLocations.map((mLocation) => {
			return {
				...mLocation,
				distance: Math.round(
					(haversine({ lat: parseFloat(mLocation.map.lat), lng: parseFloat(mLocation.map.lng) },
						{ lng: newCoordinates.lng, lat: newCoordinates.lat }) * 0.000621) * 10 / 10
				)
			}
		});
		const sortedLocations = newCoordinates.sort(({ distance: a }, { distance: b }) => b - a);
		const sortLocs = newCoordinates.sort((a, b) => {
			if (a.distance > b.distance) {
				return 1
			} else {
				return -a
			}
		})


		newCoordinates.sort((a, b) => {
			return a.distance - b.distance;
		});

		let locationsToUse;
		if (navigator.userAgent.toLowerCase().indexOf('firefox') !== -1) {
			locationsToUse = newCoordinates
		} else if (navigator.userAgent.toLowerCase().indexOf('chrome') !== -1) {
			locationsToUse = newCoordinates
		} else {
			locationsToUse = newCoordinates

		}
		setManheimLocations(locationsToUse);
		setTimeout(() => {
			setLocationMessage('loaded');
		}, 1000)
	};
	//orderByDistance()

	const onMapClick = orderByDistance;

	const onLocationsPrint = () => {
		document.body.classList.add('body-print');
		window.print();
		document.body.classList.remove('body-print');
	};

	const panTo = useCallback(({ lat, lng }) => {
		mapRef.current.panTo({ lat, lng });
		mapRef.current.setZoom(8);
	}, []);

	if (loadError) return 'Error loading maps';
	if (!isLoaded) return 'Loading map';

	return (
		<ValueContext.Provider
			value={{
				manheimLocations,
				markers,
				setMarkers,
				selected,
				setSelected,
				userLocation,
				setUserLocation,
				searchLat,
				setSearchLat,
				searchLong,
				setSearchLong,
				distance,
				setDistance,
				center,
				setCenter,
				panTo,
				orderByDistance,
				isInfoWindowShowing,
				setIsInfoWindowShowing,
				onMapLoad,
				locationMessage
			}}
		>
			<Page
				panTo={panTo}
				distance={distance}
				setDistance={setDistance}
				setSearchLat={setSearchLat}
				setSearchLong={setSearchLong}
				searchLat={searchLat}
				searchLong={searchLong}
				userLocation={userLocation}
				setUserLocation={setUserLocation}
				onMapClick={() => onMapClick(manheimLocations)}
				onLocationsPrint={onLocationsPrint}
			/>
		</ValueContext.Provider>
	);

	return <div className="App">Google</div>;
}

const Search = () => {
	const stateVals = useContext(ValueContext);
	const { setSelected, setSearchLat, setSearchLong, orderByDistance, panTo, manheimLocations } = useContext(ValueContext);
	const [term, setTerm] = useState('')

	const { ready, value, suggestions: { status, data }, setValue, clearSuggestions } = usePlacesAutocomplete({
		requestOptions: {
			location: {
				lat: () => 33.748997,
				lng: () => -84.387985
			},
			radius: 200 * 1000
		}
	});

	const mainInput = useRef('')
	return (
		<>
			<Combobox
				onSelect={async (address) => {
					try {
						setSelected(null)
						const results = await getGeocode({ address });
						const { lat, lng } = await getLatLng(results[0]);

						//setValue(results[0].formatted_address);
						stateVals.panTo({ lat, lng });

						setSearchLat(lat);
						setSearchLong(lng);
						orderByDistance({ lat, lng });
					} catch (err) {
						console.log(err);
					}
				}}
				onSubmit={(e) => {
					e.preventDefault();
				}}
			>
				<ComboboxInput
					className="search-input"
					placeholder="Enter an address, city, or zip code"
					// value={value}
					disabled={!ready}
					selectOnClick
					onChange={(e) => {
						setValue(e.target.value);
						setTerm(e.target.value)
					}}
					onSubmit={(e) => {
						e.preventDefault();

					}}
				/>
				<ComboboxPopover>
					<ComboboxList key={Math.random() * Math.random()}>
						{status === 'OK' &&
							data.map(({ id, description }) => (
								<ComboboxOption onClick={(e) => {
									setTerm(description)
								}} key={Math.random()} value={description} />
							))}
					</ComboboxList>
				</ComboboxPopover>
			</Combobox>
		</>
	);
};

const Page = ({ onMapClick, onLocationsPrint }) => {
	const stateVals = useContext(ValueContext);
	const { markers, manheimLocations, onMapLoad, setCenter, selected, setSelected, center, locationMessage } = useContext(ValueContext)

	return (
		<div className="page-section page-section-white">
			<div className="container">
				<div className="flex-row flex-wrap" style={{ marginBottom: '0' }}>
					<div className="flex-col flex-col-9">
						<h2 className="h1 is-brand-blue location-finder-heading">Find a Sales Location</h2>
					</div>
					<div className="flex-col flex-col-3">
						<form className="search-contain">
							<Search className="search-input" />
						</form>
					</div>
				</div>

				<div className="flex-row flex-wrap">
					<div className="flex-col flex-col-9 location-finder-map-container">
						<GoogleMap
							mapContainerStyle={mapContainerStyle}
							zoom={6}
							center={center}
							options={options}
							onLoad={onMapLoad}
							onDragStart={() => setSelected(null)}
						// onClick={() => onMapClick()}
						// onClick={(event) => {
						// }}
						>
							{markers && markers.map((marker) => (
								<Marker
									key={Math.random() * Math.random()}
									position={{ lat: parseFloat(marker.map.lat), lng: parseFloat(marker.map.lng) }}
									icon={iconLocation}
									onClick={() => {
										setSelected(marker);
										setCenter({
											lat: parseFloat(marker.map.lat),
											lng: parseFloat(marker.map.lng)
										});
									}}
									title={marker.name}
								/>
							))}
							{selected ? (
								<InfoWindow
									key={Math.random()}
									position={{ lat: parseFloat(selected.lat), lng: parseFloat(selected.lng) }}
								// onCloseClick={() => setSelected(null)}
								>
									<div style={{ marginBottom: '20px' }}>
										<a href={`https://site.manheim.com/en/locations/us-locations/${selected.slug}.html`} target="_blank" data-location="Location Finder">
											<h5 className="is-brand-green">{selected.name}</h5>
										</a>
										<a href={`tel:${selected.phone}`} className="is-brand-blue block m-y-md">{selected.phone}</a>
										<div><b>{selected.address.replace(/&nbsp;|&amp;nbsp;/g, '\u00A0')}</b></div>
										<div><b>{selected.location_address}</b></div>
										<div><span className="location-description">{selected.location_description}</span></div>
									</div>
								</InfoWindow>
							) : null}
						</GoogleMap>
					</div>
					<div className="flex-col flex-col-3 locations-to-load">
						{locationMessage === 'loaded' ?
							<div className="location">
								{manheimLocations.map((location, index) => {
									if (index < 5 && location !== undefined) return (
										<div
											key={Math.random()}
											className="inner-location"
											onClick={() => {
												stateVals.setSelected(location)
												stateVals.panTo({
													lat: parseFloat(location.map.lat),
													lng: parseFloat(location.map.lng)
												})
											}}>
											<div className="location-icon">
												<img src={iconLocation} alt="" style={{ minWidth: '24px', maxWidth: '24px', marginTop: '3px' }} />
											</div>
											<div className="location-address" key={location.id}>
												<p>
													<span className="is-brand-green">
														<b>{location.name}</b>
													</span>
													<br />
													<span className="location-street">{location.address.replace(/&nbsp;|&amp;nbsp;/g, '\u00A0')}</span>
													<br />
													<span className="location-description">{location.location_description}</span>

													<span className="location-distance">
														{location.distance ? `${location.distance} miles away` : ''}
													</span>
												</p>
											</div>
										</div>
									)
								})}
							</div>
							: (
								<div className="location-loading">Loading locations...</div>
							)
						}
					</div>
				</div>
			</div>
		</div>
	);
};

export default App;
